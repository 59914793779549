import { useMutation } from '@tanstack/react-query';
import { Button, SelectPrimitive } from '@utima/ui';
import { Form, Select, type TypedFormState, Input } from '@utima/ui-informed';
import { ChevronLeft } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { aiCoreApi } from '@/services/aiCoreClient';
import { lang } from '@/services/i18n';
import { AuthSource } from '@/store/slices/userSlice';
import { useBoundStore } from '@/store/store';

type FormValues = {
  club: string;
  first_name: string;
  last_name: string;
  password: string;
  email: string;
};

function updateErrorMessage(
  errors: any[],
  targetCode: string,
  targetPath: string,
  newMessage: string,
): any[] {
  return errors.map(error => {
    if (error.responseCode === targetCode && error.path === targetPath) {
      return { ...error, message: newMessage };
    }
    return error;
  });
}

export function NewMember() {
  const { t } = useTranslation();
  const [clubId, setClubId] = useState('895');
  const { login, pushRoute, popRoute, user } = useBoundStore(state => ({
    login: state.login,
    pushRoute: state.pushRoute,
    popRoute: state.popRoute,
    user: state.user,
  }));
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const { mutateAsync, error, isPending } = useMutation({
    mutationFn: async (formState: TypedFormState<FormValues>) => {
      const { club, first_name, last_name, email, password } = formState.values;
      setClubId(club);
      return aiCoreApi.postMember({
        firstName: first_name,
        lastName: last_name,
        email: email,
        password: password,
        clubId: club,
        language: lang === 'cs' ? 'cs-CZ' : 'en-US',
      });
    },
    onSuccess: data => {
      login(
        {
          id: user?.id!,
          externalId: user?.externalId!,
          source: AuthSource.Efitness,
          club: clubId,
        },
        {
          token: data.authorizationData.accessToken,
          expires: data.authorizationData.expires,
        },
        data.authorizationData.refreshToken,
      );
      pushRoute('newMembership');
    },
    onError: async (err: any) => {
      const errorDecoded = await err.response?.json();
      if (errorDecoded.response.data.errors) {
        const updatedErrors = updateErrorMessage(
          errorDecoded.response.data.errors,
          '4.24',
          'password',
          t('memberRegistration.invalidPassword'),
        );
        setErrorMessages(updatedErrors);
      } else {
        setErrorMessages(['Something went wrong']);
      }
    },
  });

  return (
    <div className='size-full items-center justify-stretch overflow-auto p-8'>
      <div className='w-full rounded-2xl bg-darkGrey p-5 [&_*]:ring-offset-darkGrey'>
        <div className='relative mb-2 flex w-full items-center justify-center'>
          <Button
            onClick={() => popRoute()}
            className='absolute left-0 top-0 flex text-lg font-medium'
            size='icon-sm'
          >
            <ChevronLeft />
          </Button>
          <h4 className='text-center text-3xl font-normal leading-10 text-white'>
            {t('memberRegistration.newMember')}
          </h4>
        </div>
        <Form onSubmit={mutateAsync} className='flex flex-col gap-5'>
          <Select
            size='lg'
            defaultValue='895'
            required
            name='club'
            usePortal={false}
            label={t('membershipForm.club')}
          >
            <SelectPrimitive.Group>
              <SelectPrimitive.Label>Praha</SelectPrimitive.Label>
              <Select.Item value='895'>Anděl</Select.Item>
              <Select.Item value='1185'>Butovice</Select.Item>
              <Select.Item value='1184'>Eden</Select.Item>
              <Select.Item value='1186'>Harfa</Select.Item>
              <Select.Item value='1048'>Hostivař</Select.Item>
              <Select.Item value='896'>Karlín</Select.Item>
              <Select.Item value='2087'>Letňany</Select.Item>
              <Select.Item value='1187'>Palladium</Select.Item>
              <Select.Item value='1760'>Pankrác</Select.Item>
              <Select.Item value='2086'>SO-HO</Select.Item>
              <Select.Item value='1042'>Stodůlky</Select.Item>
              <Select.Item value='1041'>Václavské náměstí</Select.Item>
              <Select.Item value='1043'>Vinohradská</Select.Item>
              <Select.Item value='1047'>Vršovická</Select.Item>
            </SelectPrimitive.Group>
            <SelectPrimitive.Group>
              <SelectPrimitive.Label>Brno</SelectPrimitive.Label>
              <Select.Item value='1912'>Lužánky</Select.Item>
              <Select.Item value='1911'>Vlněna</Select.Item>
            </SelectPrimitive.Group>
            <SelectPrimitive.Group>
              <SelectPrimitive.Label>Ostrava</SelectPrimitive.Label>
              <Select.Item value='1804'>Ostrava Avion</Select.Item>
            </SelectPrimitive.Group>
          </Select>
          <Input name='first_name' label={t('memberRegistration.firstName')} />
          <Input name='last_name' label={t('memberRegistration.surName')} />
          <Input
            name='password'
            passwordPreview
            type='password'
            label={t('memberRegistration.password')}
          />
          <Input
            name='email'
            type='email'
            label={t('memberRegistration.email')}
          />

          {error &&
            errorMessages.length > 0 &&
            errorMessages.map((error: any) => (
              <p
                key={error.responseCode}
                className='text-center text-sm font-medium text-rose-600 animate-in fade-in slide-in-from-top'
              >
                {error.message}
              </p>
            ))}
          <Button
            size='lg'
            type='submit'
            disabled={isPending}
            loading={isPending}
          >
            {t('loginForm.submit')}
          </Button>
        </Form>
      </div>
    </div>
  );
}
