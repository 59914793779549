import { useQuery } from '@tanstack/react-query';
import { Mail, Phone } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { adminApi } from '@/services/aiCoreClient';
import { queryKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';

import { ContactButton } from './ContactButton';

export interface Operator {
  id: string;
  branch: string;
  city: string;
  name: string;
  phone: string;
  email: string;
  created_at: string;
  updated_at: string | null;
}

enum BranchIds {
  Anděl = '895',
  Butovice = '1185',
  Eden = '1184',
  Harfa = '1186',
  Hostivař = '1048',
  Karlín = '896',
  Letňany = '2087',
  Palladium = '1187',
  Pankrác = '1760',
  SOHO = '2086',
  Stodůlky = '1042',
  VáclavskéNáměstí = '1041',
  Vinohradská = '1043',
  Vršovická = '1047',
  Lužánky = '1912',
  Vlněna = '1911',
  OstravaAvion = '1804',
}

function getName(value: string): string {
  const entry = Object.entries(BranchIds).find(([, val]) => val === value);
  return entry ? entry[0] : 'Unknown';
}

export function HelpContact() {
  const [user] = useBoundStore(state => [state.user]);
  const { t } = useTranslation();

  const clubName = user?.club ? getName(user?.club) : 'Unknown';

  const { data: operators } = useQuery({
    queryKey: queryKeys.operators(),
    queryFn: () => adminApi.getOperatorByBranch(clubName),
  });

  const phone = operators?.phone || '+420 255 710 288'; // Default Vinohrady
  const email = operators?.email || 'vinohradska@formfactory.cz'; // Default Vinohrady

  return (
    <div className='flex flex-row gap-4 pt-3'>
      <ContactButton Icon={Phone} text={t('help.call')} href={`tel:${phone}`} />
      <ContactButton
        Icon={Mail}
        text={t('help.email')}
        href={`mailto:${email}`}
      />
    </div>
  );
}
