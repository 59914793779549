import '@/services/i18n';

import { ErrorBoundary } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { FormTranslationsContext } from '@utima/ui-informed';
import { lazy, StrictMode, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './app.css?inline';
import { AppSettings } from './constants/AppSettings';
import { defaultQueryClient } from './services/queryClient';
import { Widget } from './Widget';

import { version } from '../package.json';

const ReactQueryDevtools = lazy(() =>
  import('@tanstack/react-query-devtools').then(res => ({
    default: res.ReactQueryDevtools,
  })),
);

export function App() {
  const { t } = useTranslation();
  const translations = useMemo(
    () => ({
      labels: {
        optional: t('glossary.optional'),
      },
      errors: {
        required: t('glossary.required'),
      },
    }),
    [t],
  );

  return (
    <StrictMode>
      <ErrorBoundary>
        <FormTranslationsContext.Provider value={translations}>
          <QueryClientProvider client={defaultQueryClient}>
            {/* .ff-chatbot-app is used for tailwind scoping */}
            {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
            <div className='ff-chatbot-app' data-version={version}>
              <div className='font-roboto'>
                {/* Handles style injection to shadow dom in Web Component */}
                <style dangerouslySetInnerHTML={{ __html: styles }} />

                {/* Chatbot widget */}
                <Widget />
              </div>
            </div>
            {AppSettings.debug && (
              <Suspense fallback={null}>
                <ReactQueryDevtools buttonPosition='bottom-left' />
              </Suspense>
            )}
          </QueryClientProvider>
        </FormTranslationsContext.Provider>
      </ErrorBoundary>
    </StrictMode>
  );
}
