import './uiOverrides';
import { init } from '@sentry/react';
import { createElement } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { AppSettings } from './constants/AppSettings';

init({
  dsn: AppSettings.sentry.dsn,
  integrations: [],
});

class ChatbotWebComponent extends HTMLElement {
  mountPoint: HTMLDivElement;

  constructor() {
    super();

    // Preload google webfonts
    const preloadGStatic = document.createElement('link');
    preloadGStatic.rel = 'preconnect';
    preloadGStatic.href = 'https://fonts.gstatic.com';
    preloadGStatic.crossOrigin = 'anonymous';

    const preloadGoogleApis = document.createElement('link');
    preloadGoogleApis.rel = 'preconnect';
    preloadGoogleApis.href = 'https://fonts.googleapis.com';

    const robotoFont = document.createElement('link');
    robotoFont.rel = 'stylesheet';
    robotoFont.href =
      'https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,400..700;1,400..700&display=swap';

    // Append to document head
    document.head.append(preloadGStatic);
    document.head.append(preloadGoogleApis);
    document.head.append(robotoFont);

    const shadowRoot = this.attachShadow({ mode: 'open' });
    const mountPoint = document.createElement('div');

    shadowRoot.append(mountPoint);
    this.mountPoint = mountPoint;
  }

  connectedCallback() {
    const root = createRoot(this.mountPoint);
    root.render(createElement(App, null));
  }
}

customElements.define('form-factory-ai-chatboot', ChatbotWebComponent);
