import clsx from 'clsx';
import { MessageCircle } from 'lucide-react';
import { memo, type ComponentProps } from 'react';

import { useBoundStore } from '@/store/store';

import { Image } from '../image/Image';

type BubbleProps = {
  src: string;
  alt?: string;
} & Omit<ComponentProps<'button'>, 'type'>;

export const Bubble = memo(function Bubble({
  src,
  alt = 'Chatbot',
  ...restProps
}: BubbleProps) {
  const [opened, unread] = useBoundStore(state => [state.opened, state.unread]);

  return (
    <div
      className={clsx('animate-in-bubble fixed bottom-6 right-6 z-[90000]', {
        ['hidden']: opened,
      })}
    >
      <button
        className='group size-20 rounded-full'
        type='button'
        {...restProps}
      >
        <Image
          alt={alt}
          src={src}
          className='size-full select-none rounded-full border-2 border-white object-cover transition-transform group-hover:scale-105 group-active:scale-100'
        />
        <div
          className={clsx(
            'absolute bottom-0 left-0 flex size-8 items-center justify-center rounded-full border-2 border-white text-sm transition-colors',
            {
              ['bg-red text-white animate-bounce-short']: unread,
              ['bg-white text-black']: !unread,
            },
          )}
        >
          {unread || (
            <MessageCircle
              className='size-[18px] rotate-90 -scale-100'
              strokeWidth={2.5}
            />
          )}
        </div>
      </button>
    </div>
  );
});
