import clsx from 'clsx';
import { memo, type ReactNode } from 'react';

import { useBoundStore } from '@/store/store';

import { TopBar } from '../topBar/TopBar';

export type ChatWindowProps = {
  children?: ReactNode;
};

export const ChatWindow = memo(function ChatWindow({
  children,
}: ChatWindowProps) {
  const opened = useBoundStore(state => state.opened);

  return (
    <div
      aria-hidden={!opened}
      tabIndex={opened ? undefined : -1}
      className={clsx(
        'animate-in-chat fixed bottom-0 right-0 z-[10000] flex size-full flex-col bg-black/90 backdrop-blur-md sm:w-[420px] sm:max-w-[420px]',
        {
          ['hidden']: !opened,
        },
      )}
    >
      <div className='shrink-0 overflow-hidden'>
        <TopBar />
      </div>
      <div className='grow overflow-hidden'>{children}</div>
    </div>
  );
});
