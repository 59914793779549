import { captureException, withScope } from '@sentry/react';
import ky, { type BeforeRequestHook, type Options } from 'ky';

import type { Faq } from '@/components/faqs/Faqs';
import type { Operator } from '@/components/helpContact/HelpContact';
import { AppSettings } from '@/constants/AppSettings';
import type { Conversation } from '@/hooks/chatTypes';
import { isTokenExpired } from '@/lib/utils';
import { AuthSource } from '@/store/slices/userSlice';
import { useBoundStore } from '@/store/store';
import type { RatingEnum } from '@/types';

export type AuthMemberResponse = {
  id: string;
  name: string;
  expires: string;
  accessToken: string;
  tokenType: string;
  refreshToken: string;
  issued: string;
  expiresIn: number;
};

export type MemberInfo = {
  memberId: number;
  firstName: string;
  lastName: string;
  email: string;
};

export type RegistrationUserResponse = {
  memberId: number;
  authorizationData: AuthMemberResponse;
};

export type UserResponse = {
  id: string;
  externalId: string | null;
  source: AuthSource;
  createdAt: string;
  updatedAt: string | null;
};

export type ChatResponse = {
  message: string;
  conversation: Conversation;
};

export type CreateThreadsResponse = {
  id: string;
  userId: string;
  createdAt: string;
  title: string;
};

export type ThreadsResponse = {
  id: string;
  title: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
}[];

export type MembershipDefinition = {
  id: number;
  clubId?: number;
  name?: string;
  installmentPrice: number;
  installmentsNumber: number;
  validFrom?: string;
  validTo?: string;
  internetName?: string;
  internetDescription?: string;
  internetPriority?: number;
  hasService: boolean;
  joinFee?: number;
  administrativeFee?: number;
  periodTime?: number;
  periodType?: number;
  paymentForm?: number;
  periodOfNotice?: number;
  periodOfNoticeType?: number;
  categoryId?: number;
  label?: string;
};

export type MembershipDefinitionResponse = {
  results: MembershipDefinition[];
};

export type MembershipConfirmationsResponse = {
  installmentIdsForPayment: [];
};

export type PaymentResponse = {
  redirectUrl: string;
};
/**
 * eFitness API authentication hook. Makes sue to keep the
 * access token fresh, before doing any request.
 */
export const efitnessRefreshHook: BeforeRequestHook = async request => {
  const accessToken = useBoundStore.getState().accessToken;
  const refreshToken = useBoundStore.getState().refreshToken;
  const user = useBoundStore.getState().user;

  /**
   * Don't do anything if the access token is not expired. Or there
   * are some missing data.
   */
  if (
    !accessToken ||
    !isTokenExpired(accessToken.expires) ||
    !refreshToken ||
    !user ||
    !user?.club ||
    user.source !== AuthSource.Efitness
  ) {
    return request;
  }

  // Refresh the token
  try {
    const data = await aiCoreApi.authEFitnessRefresh({
      club: user.club,
      refreshToken: refreshToken,
      accessToken: accessToken.token,
    });

    // New refresh and access tokens
    useBoundStore.getState().setRefreshToken(data.refreshToken);
    useBoundStore.getState().setAccessToken({
      token: data.accessToken,
      expires: data.expires,
    });
  } catch {
    useBoundStore.getState().logout();
  }
};

/**
 * ky API client instance for communicating with the
 * AI core backend.
 */
export const aiCoreClient = ky.create({
  prefixUrl: AppSettings.api.baseURL,
  hooks: {
    beforeError: [
      error => {
        withScope(scope => {
          scope.setExtras({ error });
          captureException(error);
        });

        if (import.meta.env.DEV) {
          console.error(JSON.stringify(error, null, 2));
        }

        return error;
      },
    ],
  },
});

/**
 * ky API client instance for communicating with the
 * AI core backend.
 */
export const adminApiClient = ky.create({
  prefixUrl: AppSettings.api.adminApiURL,
  hooks: {
    beforeError: [
      error => {
        withScope(scope => {
          scope.setExtras({ error });
          captureException(error);
        });

        if (import.meta.env.DEV) {
          console.error(JSON.stringify(error, null, 2));
        }

        return error;
      },
    ],
  },
});

/**
 * Simple service for communicating with the AI core backend.
 */
export const aiCoreApi = {
  /**
   * Authenticate a local user, returns the user details.
   */
  authLocal: async (
    data: { userId: string },
    options?: Options,
  ): Promise<{ user: UserResponse }> =>
    aiCoreClient
      .post(`auth/local`, {
        json: data,
        ...options,
      })
      .json(),

  /**
   * Authenticate a eFitness member, returns the user details.
   */
  authEFitness: async (
    data: {
      club: string;
      username: string;
      password: string;
    },
    options?: Options,
  ): Promise<{
    user: UserResponse;
    member: AuthMemberResponse;
  }> =>
    aiCoreClient
      .post(`auth/efitness`, {
        json: data,
        ...options,
      })
      .json(),

  /**
   * Get member info
   */
  getMemberInfo: async (
    data: {
      clubId: string;
      accessToken: string;
      language: string;
    },
    options?: Options,
  ): Promise<MemberInfo> =>
    aiCoreClient
      .get(`clubs/${data.clubId}/members`, {
        ...options,
        headers: {
          memberToken: data.accessToken,
          language: data.language,
        },
      })
      .json(),

  /**
   * Endpoint for refreshing eFitness access token.
   */
  authEFitnessRefresh: async (
    data: {
      club: string;
      refreshToken: string;
      accessToken: string;
    },
    options?: Options,
  ): Promise<AuthMemberResponse> =>
    aiCoreClient
      .post(`auth/efitness/refresh`, {
        json: data,
        ...options,
      })
      .json(),

  /**
   * Send a chat message to the AI core backend.
   */
  chat: async (
    data: {
      userId: string;
      message: string;
      threadId: string;
    },
    options?: Options,
  ): Promise<ChatResponse> =>
    aiCoreClient
      .post(`chat`, {
        json: data,
        ...options,
      })
      .json(),

  /**
   * Get message history for given thread and ID
   */
  getMessages: async (
    data: {
      userId: string;
      threadId: string;
    },
    options?: Options,
  ): Promise<Conversation[]> =>
    aiCoreClient
      .get(`user/${data.userId}/threads/${data.threadId}/messages`, {
        ...options,
      })
      .json(),

  /**
   * Get user threads.
   */
  getThreads: async (
    data: {
      userId: string;
    },
    options?: Options,
  ): Promise<ThreadsResponse> =>
    aiCoreClient
      .get(`user/${data.userId}/threads`, {
        ...options,
      })
      .json(),

  /**
   * Create a new thread for the user.
   */
  createThreads: async (
    data: {
      userId: string;
    },
    options?: Options,
  ): Promise<CreateThreadsResponse> =>
    aiCoreClient
      .post(`user/${data.userId}/threads`, {
        ...options,
      })
      .json(),

  /**
   * Post feedback for the conversation.
   */
  feedback: async (
    data: {
      conversationId: string;
      message: string | undefined;
      rating: RatingEnum;
    },
    options?: Options,
  ): Promise<Conversation> =>
    aiCoreClient
      .post(`feedback/${data.conversationId}`, {
        json: { message: data.message, rating: data.rating },
      })
      .json(),

  /**
   * Get membership definitions
   */
  getMembershipDefinitions: async (
    data: {
      clubId: string;
      language: string;
    },
    options?: Options,
  ): Promise<MembershipDefinitionResponse> =>
    aiCoreClient
      .get(`clubs/${data.clubId}/membership-definitions`, {
        headers: {
          language: data.language,
        },
        ...options,
      })
      .json(),

  /**
   * Get consent definitions
   */
  getConsentDefinitions: async (
    data: {
      clubId: string;
      id: string;
      language: string;
    },
    options?: Options,
  ): Promise<any> =>
    aiCoreClient
      .get(
        `clubs/${data.clubId}/membership-definitions/${data.id}/consent-definitions`,
        {
          headers: {
            language: data.language,
          },
          ...options,
        },
      )
      .json(),

  getServiceDefinitions: async (
    data: {
      clubId: string;
      id: string;
      language: string;
    },
    options?: Options,
  ): Promise<MembershipDefinitionResponse[]> =>
    aiCoreClient
      .get(
        `clubs/${data.clubId}/membership-definitions/${data.id}/service-definitions`,
        {
          ...options,
          headers: {
            language: data.language,
          },
        },
      )
      .json(),

  postMembership: async (
    // Depends on definitions
    data: any,
    options?: Options,
  ): Promise<MembershipConfirmationsResponse> => {
    return aiCoreClient
      .post(`clubs/${data.data.clubId}/members/memberships`, {
        json: data.data,
        headers: {
          memberToken: data.headers.memberToken,
          language: data.headers.language,
        },
        ...options,
      })
      .json();
  },

  postPayment: async (
    // Depends on definitions
    data: any,
    options?: Options,
  ): Promise<PaymentResponse> => {
    return aiCoreClient
      .post(
        `clubs/${data.data.clubId}/members/payments/online-payments/transactions`,
        {
          json: data.data,
          headers: {
            memberToken: data.headers.memberToken,
            language: data.headers.language,
          },
          ...options,
        },
      )
      .json();
  },
  /**
   * Create member on Efitness
   */
  postMember: async (
    data: {
      clubId: string;
      firstName: string;
      lastName: string;
      email: string;
      password: string;
      language: string;
    },
    options?: Options,
  ): Promise<RegistrationUserResponse> =>
    aiCoreClient
      .post(`clubs/${data.clubId}/member`, {
        json: data,
        ...options,
        headers: {
          language: data.language,
        },
      })
      .json(),

  /**
   * Save the conversation to the database.
   */
  saveConversation: async (
    data: {
      userMessage: string;
      systemMessage: string;
      userId: string;
      threadId: string;
    },
    options?: Options,
  ) =>
    aiCoreClient
      .post(`user/${data.userId}/threads/${data.threadId}/messages`, {
        json: {
          userMessage: {
            message: data.userMessage,
            createdAt: new Date().toISOString(),
          },
          systemMessage: {
            message: data.systemMessage,
            createdAt: new Date().toISOString(),
          },
        },
        ...options,
      })
      .json(),
};

/**
 * Simple service for communicating with the ADMIN api backend.
 */
export const adminApi = {
  /**
   * Fetch active FAQs.
   */
  fetchActiveFaqs: async (options?: Options): Promise<Faq[]> =>
    adminApiClient
      .get(`api/faqs-active`, {
        ...options,
      })
      .json(),
  /**
   * Get operator by branch
   */
  getOperatorByBranch: async (
    branch: string,
    options?: Options,
  ): Promise<Operator> =>
    adminApiClient
      .get(`api/operatorsById/${branch}`, {
        ...options,
      })
      .json(),
  /**
   * Increment FAQ by ID.
   */
  incrementFaqs: async (id: string, options?: Options): Promise<Faq> =>
    adminApiClient
      .post(`api/faqs-clicked`, {
        json: { faqId: id },
      })
      .json(),
};
