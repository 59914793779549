import type { SetFieldType } from 'type-fest';
import type { StateCreator } from 'zustand';

import { defaultQueryClient } from '@/services/queryClient';

import type { AppSlice } from './appSlice';
import type { RouterSlice } from './routerSlice';

export enum AuthSource {
  Efitness = 'efitness',
  Local = 'local',
}

export type User = {
  id: string;
  externalId: string | null;
  source: AuthSource;
  club?: string | null;
  name?: string | null;
  email?: string | null;
};

export type AccessToken = {
  token: string;
  expires: Date;
};

type AccessTokenParam = SetFieldType<AccessToken, 'expires', string>;

/**
 * Parses token expiration date form weird MS format
 */
function parseTokenExpires(expires: string | Date): Date {
  return typeof expires === 'string' ? new Date(expires + 'Z') : expires;
}

export interface UserSlice {
  user: User | null;
  login: (
    user: User,
    accessToken?: AccessTokenParam,
    refreshToken?: string,
  ) => void;
  logout: () => void;
  accessToken?: AccessToken | null;
  setAccessToken: (token: AccessTokenParam) => void;
  refreshToken?: string | null;
  setRefreshToken: (token: string | null) => void;
  registrationMemberData?: any;
  setRegistrationMemberData: (data: any) => void;
  setUser: (user: User) => void;
}

export const createUserSlice: StateCreator<
  UserSlice & RouterSlice & AppSlice,
  [],
  [],
  UserSlice
> = (set, get) => ({
  user: null,
  setAccessToken: token =>
    set({
      accessToken: {
        token: token.token,
        expires: parseTokenExpires(token.expires),
      },
    }),
  setRefreshToken: token =>
    set({
      refreshToken: token,
    }),
  login: (user, accessToken, refreshToken) =>
    set({
      user,
      accessToken: accessToken
        ? {
            token: accessToken.token,
            expires: parseTokenExpires(accessToken.expires),
          }
        : null,
      refreshToken: refreshToken || null,
    }),
  logout: () => {
    // Clear query cache
    defaultQueryClient.clear();
    get().setShowHelp(false);
    // Clear global state
    set({ user: null, accessToken: null, refreshToken: null });
    // Redirect to welcome route
    get().pushRoute('welcome');
  },
  setRegistrationMemberData: data => set({ registrationMemberData: data }),
  setUser: user => set({ user }),
});
